<template>
  <div class="home">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t("manual.title") }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-block">
      <div class="page-block-header">
        <div class="content-title">
          <p>{{ $t("manual.title") }}</p>
        </div>
      </div>
    </div>
    <div class="page-content-fill">
      <object
        :data="manual_url"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        alt : <a :href="manual_url">{{ $t("manual.title") }}</a>
      </object>
    </div>
  </div>
</template>

<script>
import apiURL from "@/data/api";
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
export default {
  name: "Manual",
  components: {},
  data() {
    return {
      sui: null,
    };
  },
  methods: { ...mapActions(["switchDefaultPage"]) },
  beforeMount() {
    this.switchDefaultPage("/dashboard/user-manual");
  },
  computed: {
    ...mapState({
      app_name: (state) => state.app_name,
      lang_status: (state) => state.lang_status,
    }),
    manual_url() {
      if (this.lang_status === "zh_cn") {
        return process.env.VUE_APP_API_BASE_URL + apiURL.static.mnual_cn;
      } else {
        return process.env.VUE_APP_API_BASE_URL + apiURL.static.mnual_en;
      }
    },
  },
  async mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/style/main";
</style>


